.DateRangePickerInput_calendarIcon {
  padding: 0;
  margin: 0;
  padding-top: 2px;
  padding-left: 7px;
}

.DateInput__small {
  width: 130px;
}

.DateInput_input__small {
  padding: 0;
  padding-top: 4px;
  text-align: center;
}

.DateRangePickerInput_arrow_svg__small {
  width: 15px;
  height: 15px;
}

.DateRangePicker {
  margin: 7px;
  margin-top: 4px;
}