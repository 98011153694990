h2, h3 {
  font-variant: small-caps;
  margin-bottom: 0!important;
  margin-left: 0 !important;
}
.collapsible .content {
    padding: 6px;
    /* background-color: #eeeeee; */
}
.collapsible .header {
    /* background-color: #dddddd; */
    padding: 6px;
    cursor: pointer;
}

.cs-message__avatar {
    box-sizing: border-box;
    margin: 0 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 20px;
}
.cs-message__content {
  padding:2px
}
.MuiFormControl-marginDense {
    margin-top: 2px!important;
    margin-bottom: 2px!important;
}
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 0!important;
    padding-right: 5px!important;
}

/* .Composer-actions[data-action-icon="plus"] {
    order: 1;
    margin: 0 8px 0 0;
}

.Composer-inputWrap {
    order: 2;
} */

.ChatApp {
    border-radius: 14px;
    line-height:1;
}

.MessageList {
  font-size:13px;
  padding:3px;
}
/* 
.Message {
  height:27px;
} */

.ChatFooter {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}

/* .cs-message-input__content-editor {
  position:absolute
} */
.cs-message__header {
    font-size: .7em;
}
.cs-main-container {
  border-radius:25px;
}

.ant-card-body {
  padding: 7px;
}
.ant-form-item {
  padding: 7px;
}
.ant-modal-body {
  padding: 7px;
}
.ant-input-number {
  min-width:100px;
}
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height:24px!important;
  line-height:24px!important;
}

.ant-table-thead>tr>th {
  font-size: 14px!important;
  font-weight:400;
  text-transform: capitalize;
}

.ant-card-body h1 {
  margin-top:7px!important;
  font-size:20px;
  color: #333!important;
}

table > thead > tr > td {
  line-height: 1.42857143;
  border: 1px solid #ddd ;
}
table  {
  border: 1px solid #ddd ;
  width:100%;
}

table  {
  width: 100% ;
  border-collapse: collapse;
}

tr:hover {
  background-color: #f5f5f5;
}

th {
  font-size: 12px
}

td, th {
  border: none;
  text-align: left;
  vertical-align: middle;
  padding: 5px 4px;
}

tr {
  background-color: white;
  font-size: 14px;
  line-height: 21px;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}
.zui-table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    /* font: normal 13px Arial, sans-serif; */
}
.zui-table thead th {
    /* background-color: #DDEFEF; */
    border: solid 1px #DDEEEE;
    color: #336B6B;
    padding: 5px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
}
.zui-table tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 5px;
    text-shadow: 1px 1px 1px #fff;
}
.zui-table tr:nth-child(even) {
  background-color: #f9f9f9!important;
}
.zui-table-rounded {
    border: none;
}
.zui-table-rounded thead th {
    border: none;
    text-shadow: 1px 1px 1px #ccc;
    color: #333;
}
.zui-table-rounded thead th:first-child {
    border-radius: 10px 0 0 0;
}
.zui-table-rounded thead th:last-child {
    border-radius: 0 10px 0 0;
}
.zui-table-rounded tbody td {
    border: none;
    border-top: solid 1px #957030;
    background-color: #DDEEEE;
}

.zui-table-rounded tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}
.zui-table-rounded tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
}

.ant-input {
  border-top-left-radius:4px;
  border-bottom-left-radius:4px;
  }

  .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button, .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-right-radius:4px;
  border-bottom-right-radius:4px;
  }

.ant-input:focus {
  background-color: lightblue;
  }

.editable {  padding: 7px;
  font-family: Lato;
  border:none;
  resize: none;
  margin: 0;
  padding:7px 14px 7px 7px; 
  display: inline
}

.editable:hover {
  border: 1px solid lightblue;
  border-radius: 4px;
}

thead {
  border-bottom-width: 1px;
  text-transform: capitalize;
  color: #404357;
  font-weight: 400;
  padding-bottom: 5px;
  font-size: 16px;
}

.responsive {
  padding: 2px 7px
}
.narrow {
  width: 50px
}

.medium {
  width: 100px
}
  .wide {
    width: 150px
}
select {
  padding: 6px;
}
/*
.ant-row-flex {
  line-height: 2;
  height: 30px;
}
.ant-popover {
  font-size:18px;
}
.ant-popover-message > .anticon {
  line-height: 2.5!important;
  margin-left: 6px!important;
}
.ant-popover-message-title {
  padding-left: 28px!important;
}
.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 3px solid #e8e8e8;
  padding-top: 7px;
}
.ant-menu-item {
  margin-bottom: 7px;
  font-size: 18px !important;
  font-weight: 400!important;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 16px;
    font-size: 18px !important;
    font-weight: 400!important;
    line-height: 40px;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
*/
.numberCircle {
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 32px;
  border: 2px solid #666;
}
span.step {
  background: #41B28B;
  border-radius: 1.0em;
  -moz-border-radius: 1.0em;
  -webkit-border-radius: 1.0em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1em;
  margin-right: 7px;
  text-align: center;
  width: 1em;
  font-size:32px;
  margin-bottom: 14px
}
span.stepLarge {
  background: #41B28B;
  border-radius: 1.0em;
  -moz-border-radius: 1.0em;
  -webkit-border-radius: 1.0em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1em;
  margin-right: 7px;
  text-align: center;
  width: 1em;
  font-size:28px;
  margin-bottom: 14px
}
input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
span.text {

  display: inline-block;
  font-weight: bold;
  text-align: center;
  font-size:18px;
  margin-left: 7px;
  line-height: 24px;
}
span.textHeader {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  color: #404357;
  font-weight: 400;
  padding-right:14px;
  display: inline-block;
  width: 138px;
  line-height: 24px;
}
span.textLarge {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  font-size:24px;
  margin-left: 7px;
}
input:read-only {
  border:none!important;
}
span.text .ant-input {
  font-size:18px;
  border: 1px solid #d9d9d9!important;
}

span.status {
   height: 40px;
    padding: 3px 20px;
    font-size: 18px;
    color: #fff;
    border: 1px solid #d9d9d9;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }

